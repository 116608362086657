
import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import ZoneAutocomplete from "@/components/General/Autocompletes/Localisation/ZoneAutocomplete.vue";
import { ZoneInfos } from "prometheus-synced-ui";

@Component({
  components: { ZoneAutocomplete },
})
export default class ZoneInput extends Vue {
  @Ref("form")
  form!: any;

  @Prop({ required: true })
  equipmentExists!: boolean;

  @Prop({ required: true })
  equipmentId!: string | null;

  @Prop({ required: true })
  zoneId!: string | null;

  @Prop({ required: true })
  zone!: ZoneInfos | null;

  @Prop({ required: true })
  zoneTag!: string | null;

  exists: boolean = true;
  valid: boolean = true;

  mounted() {
    this.handleEquipmentExists();
  }

  handleEquipmentExists() {
    this.exists = this.equipmentExists;
  }

  onExistsInput(value: boolean) {
    this.exists = value;
    if (this.exists) {
      this.$emit("update:zone-tag", null);
    } else {
      this.$emit("update:zone-id", null);
    }
  }

  @Watch("equipmentExists")
  onPropChange = this.handleEquipmentExists;
}
