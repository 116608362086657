
import { Component, Prop, Vue } from "vue-property-decorator";

import PowerPlantAutocomplete from "@/components/General/Autocompletes/Localisation/PowerPlantAutocompleteWrapper.vue";
import NuclearUnitAutocomplete from "@/components/General/Autocompletes/Localisation/DependentNuclearUnitAutocomplete.vue";
import HydraulicSystemAutocomplete from "@/components/General/Autocompletes/Localisation/HydraulicSystemAutocomplete.vue";
import { NuclearUnitInfos, PowerPlantInfos } from "prometheus-synced-ui";
import { HydraulicSystemInfos } from "@/domain";

@Component({
  components: {
    PowerPlantAutocomplete,
    NuclearUnitAutocomplete,
    HydraulicSystemAutocomplete,
  },
})
export default class HighLocalisationInput extends Vue {
  @Prop({ required: true })
  powerPlantId!: string | null;

  @Prop({ required: true })
  powerPlant!: PowerPlantInfos | null;

  @Prop({ required: true })
  nuclearUnitId!: string | null;

  @Prop({ required: true })
  nuclearUnit!: NuclearUnitInfos | null;

  @Prop({ required: true })
  systemId!: string | null;

  @Prop({ required: true })
  system!: HydraulicSystemInfos | null;
}
