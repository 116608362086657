
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import { isXlsx } from "@/tools";

@Component({
  components: {},
})
export default class FileInput extends Vue {
  @Ref("form")
  form!: any;

  @Prop({ required: true })
  file!: File | null;

  @Prop({ required: true })
  loading!: boolean;

  @Prop({ required: true })
  success!: boolean;

  isAllowed() {
    return isXlsx(this.file);
  }

  valid: boolean = true;

  onFileChange(file: File | null) {
    this.$emit("update:success", false);
    this.$emit("update:file", file);
  }
}
