
import { Component, Inject, Vue } from "vue-property-decorator";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import { IMeasureTableService } from "@/services";

import Loading from "@/views/Loading.vue";
import HighLocalisationInput from "@/components/Core/ExamReports/Historic/Import/HighLocalisationInput.vue";
import EquipmentInput from "@/components/Core/ExamReports/Historic/Import/EquipmentInput.vue";
import ZoneInput from "@/components/Core/ExamReports/Historic/Import/ZoneInput.vue";
import FileInput from "@/components/Core/ExamReports/Historic/Import/FileInput.vue";
import {
  NuclearUnitInfos,
  PowerPlantInfos,
  ZoneInfos,
} from "prometheus-synced-ui";
import { EquipmentInfos, HydraulicSystemInfos } from "@/domain";

@Component({
  components: {
    Loading,
    HighLocalisationInput,
    EquipmentInput,
    ZoneInput,
    FileInput,
  },
})
export default class HistoricImport extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  get measureTableService() {
    return this.container.resolve<IMeasureTableService>(
      S.MEASURE_TABLE_SERVICE
    );
  }

  step: number = 1;
  powerPlantId: string | null = null;
  powerPlant: PowerPlantInfos | null = null;
  nuclearUnitId: string | null = null;
  nuclearUnit: NuclearUnitInfos | null = null;
  systemId: string | null = null;
  system: HydraulicSystemInfos | null = null;
  localSystemId: string | null = null;
  equipmentId: string | null = null;
  equipment: EquipmentInfos | null = null;
  localEquipmentId: string | null = null;
  equipmentName: string | null = null;
  localEquipmentName: string | null = null;
  equipmentNgram: string | null = null;
  localEquipmentNgram: string | null = null;
  zoneId: string | null = null;
  zone: ZoneInfos | null = null;
  zoneTag: string | null = null;
  file: File | null = null;
  loading: boolean = false;
  success: boolean = false;

  resetHighLocalisation() {
    this.powerPlantId = null;
    this.powerPlant = null;
    this.nuclearUnitId = null;
    this.nuclearUnit = null;
    this.systemId = null;
    this.system = null;
  }

  resetEquipment() {
    this.equipmentId = null;
    this.equipment = null;
    this.equipmentName = null;
    this.equipmentNgram = null;
  }

  resetZone() {
    this.zoneId = null;
    this.zone = null;
    this.zoneTag = null;
  }

  onNextHighLocalisationStep() {
    if (this.localSystemId != this.systemId) {
      this.resetEquipment();
      this.resetZone();
    }
    this.saveChange();
    this.step++;
  }

  onNextEquipmentStep() {
    if (
      this.localEquipmentId != this.equipmentId ||
      this.localEquipmentName != this.equipmentName ||
      this.localEquipmentNgram != this.equipmentNgram
    ) {
      this.resetZone();
    }
    this.saveChange();
    this.step++;
  }

  saveChange() {
    this.localSystemId = this.systemId;
    this.localEquipmentId = this.equipmentId;
    this.localEquipmentName = this.equipmentName;
    this.localEquipmentNgram = this.equipmentNgram;
  }

  async importHistoric() {
    try {
      this.loading = true;
      this.success = false;
      await this.measureTableService.importHistoric({
        systemId: this.systemId!,
        equipmentId: this.equipmentId,
        equipmentName: this.equipmentName,
        equipmentNgram: this.equipmentNgram,
        zoneId: this.zoneId,
        zoneTag: this.zoneTag,
        file: this.file!,
      });
      this.success = true;
    } finally {
      this.loading = false;
    }
  }

  onRestart() {
    this.step = 1;
    this.resetZone();
    this.resetEquipment();
    this.resetHighLocalisation();
  }
}
