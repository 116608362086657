
import { Component, Prop, Ref, Vue } from "vue-property-decorator";

import EquipmentAutocomplete from "@/components/General/Autocompletes/Localisation/EquipmentAutocomplete.vue";

@Component({
  components: { EquipmentAutocomplete },
})
export default class EquipmentInput extends Vue {
  @Ref("form")
  form!: any;

  @Prop({ required: true })
  equipmentId!: string | null;

  @Prop({ required: true })
  equipment!: EquipmentInput | null;

  @Prop({ required: true })
  equipmentName!: string | null;

  @Prop({ required: true })
  equipmentNgram!: string | null;

  @Prop({ required: true })
  systemId!: string | null;

  exists: boolean = true;
  valid: boolean = true;

  onExistsInput(value: boolean) {
    this.exists = value;
    if (this.exists) {
      this.$emit("update:equipment-name", null);
      this.$emit("update:equipment-ngram", null);
    } else {
      this.$emit("update:equipment-id", null);
    }
  }
}
